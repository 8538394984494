/**** General ****/
//$font-family: 'Roboto', sans-serif;
$logo-font: "MoonHouse";

$top-navbar-height: 46px;

$sidebar-width: 200px;
$sidebar-compact-width: 130px;
$sidebar-mini-width: 70px;

$horizontal-menu-height: 38px;
$horizontal-compact-menu-height: 52px;
$horizontal-mini-menu-height: 46px;

$chat-person-search-media-height: 32px;
$chat-window-header-height: 30px;
$chat-window-footer-height: 46px;

$footer-height: 28px;

.pointer {
    cursor: pointer;
}
