$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
// $fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: "MoonHouse";
  src: url("./assets/fonts/moonhouse.eot");
  src: url("./assets/fonts/moonhouse.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/moonhouse.ttf") format("truetype");
}

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

@import "intl-tel-input/build/css/intlTelInput.css";

@import "~bootstrap-icons/font/bootstrap-icons.css";

// @import '~mdb-ui-kit/css/mdb.min.css';

// .form-control{
//   padding-inline-start: 10px !important;
// }

@-webkit-keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@-moz-keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}

@-webkit-keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@-moz-keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}

@-webkit-keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
@-moz-keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
@keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}

.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.3s linear;
  -moz-transition: visibility 0.5s, opacity 0.3s linear;
  transition: visibility 0.5s, opacity 0.3s linear;
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  .loader-container {
    height: 100%;
    width: 100%;
    display: table;
    .loader-inner {
      vertical-align: middle;
      height: 100%;
      display: table-cell;
      .loader {
        width: 80px;
        margin: auto;
        position: relative;
        height: 60px;
        .mask {
          position: absolute;
          overflow: hidden;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          .plane {
            background: #dd1b16;
            width: 400%;
            height: 100%;
            position: absolute;
          }
        }
        #top {
          width: 53px;
          height: 15px;
          left: 20px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);
          .plane {
            -webkit-animation: trans1 1.3s ease-in infinite 0s backwards;
            -moz-animation: trans1 1.3s ease-in infinite 0s backwards;
            animation: trans1 1.3s ease-in infinite 0s backwards;
          }
        }
        #middle {
          width: 33px;
          height: 15px;
          left: 20px;
          top: 15px;
          -webkit-transform: skew(-15deg, 40deg);
          -moz-transform: skew(-15deg, 40deg);
          transform: skew(-15deg, 40deg);
          .plane {
            transform: translate3d(0px, 0, 0);
            background: #dd1b16;
            -webkit-animation: trans2 1.3s linear infinite 0.3s backwards;
            -moz-animation: trans2 1.3s linear infinite 0.3s backwards;
            animation: trans2 1.3s linear infinite 0.3s backwards;
          }
        }
        #bottom {
          width: 53px;
          height: 15px;
          top: 30px;
          -webkit-transform: skew(-15deg, 0);
          -moz-transform: skew(-15deg, 0);
          transform: skew(-15deg, 0);
          .plane {
            -webkit-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            -moz-animation: trans3 1.3s ease-out infinite 0.7s backwards;
            animation: trans3 1.3s ease-out infinite 0.7s backwards;
          }
        }
        p.loader-text {
          color: #000;
          position: absolute;
          top: 42px;
          font-family: "MoonHouse";
          font-size: 16px;
          span {
            color: #dd1b16;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 15px;
  // height: 20px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

app-timesheet {
  .form-control {
    padding: 0px !important;
  }
  .form-control {
    border: 1px solid #eff3f6 !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.mat-row:hover .mat-cell {
  background-color: #f7f7fe;
}

.table-hover {
  tr:hover {
    background-color: #f7f7fe;
  }
}

.ngx-pagination {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin: 0px !important;
}

.bg-primary,
.btn-primary {
  background-color: #2f3e9e !important;
}
// $active:'';

mat-form-field {
  flex-direction: inherit !important;
  height: 42px !important;
  // border: 1px solid #ced4da !important;
  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
  }
  .mat-mdc-floating-label {
    top: 20px !important;
  }
  .mdc-floating-label--float-above {
    top: 28px !important;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #ced4da !important;
  }

  mat-icon {
    font-size: 14px !important;
  }

  // .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  // .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  // .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  //   border-color: rgb(255 12 12 / 38%);
  // }
}

mat-option {
  font-size: 12px !important;
  min-height: 36px !important;
  // border-bottom:1px solid #ced4da !important;
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  white-space: normal;
  font-size: 12px;
  color: grey;
  line-height: 1rem;
  /* overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loader-inner {
  img {
    width: 100px;
    animation: zoom-in-zoom-out 1s ease infinite;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-control {
  font-size: 12px !important;
}

.breadcrumb {
  font-size: 12px !important;
}

.fa {
  font-size: 12px !important;
}

table {
  margin: 0px !important;
  font-size: 12px !important;
  td {
    padding: 5px !important;
  }
  tr {
    border-color: #ebeaf2;
  }
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: 12px;
}

.mat-mdc-select-value {
  font-size: 12px;
}

form{
  font-size: 12px !important;
}

label{
  font-size: 12px !important;
}

// button{
//   font-size: 12px !important;
// }